<div class="holder">
	<a routerLink="/adasok/{{ broadcast.category.slug }}/{{ broadcast.slug || '' }}">
		<img
			[src]=" broadcast.featured_image || '/assets/img/rallye-default.png'"
			class="img-fluid mb-2"
			alt="{{ broadcast.title }}"
		/>
	</a>
	<div class="tag" [ngClass]="{'bg-orange': broadcast.category.slug === 'rallye', 'bg-purple': broadcast.category.slug === 'street'}">#{{ broadcast.category.slug || 'rallye' }}</div>
	<div class="title eq">
		<a routerLink="/adasok/{{ broadcast.category.slug }}/{{ broadcast.slug || '' }}">
			<h2>{{ broadcast.title || '' }}</h2>
		</a>
	</div>
</div>
