<app-loader [isLoading]="true" *ngIf="isLoading"></app-loader>
<ng-container *ngIf="featuredPost$ | async">
<section>
	<div class="container">
		<div class="row">
			<div class="col-md-9">
				<app-selected-post *ngIf="featuredPost$ | async" [post]="featuredPost$ | async"></app-selected-post>
				<div class="row posts">
					<div class="col-md-4" *ngFor="let mainPost of mainPosts$ | async">
						<app-small-post [post]="mainPost"></app-small-post>
					</div>
				</div>
			</div>
			<div class="col-md-3">
				<div class="timeline">
					<div class="header">
						<a routerLink="/program">
							<p>Program</p>
							<img src="/assets/img/right_arrow.svg" class="arrow" />
						</a>
					</div>
					<ng-container *ngFor="let program of programs$ | async">
						<a *ngIf="program.broadcast" routerLink="/adasok/{{ program.broadcast.category.slug }}/{{ program.broadcast.slug || '' }}">
							<div class="program">
								<div class="date">
									<p class="program-title mb-0">{{ program.program_date.day }}</p>
									<p class="program-subtitle mb-0 text-right">{{ program.program_date.time }}</p>
								</div>
								<div class="titles">
									<p class="program-title mb-0">{{ program.title }}</p>
									<p class="program-subtitle mb-0">{{ program.subtitle || '' }}</p>
								</div>
							</div>
						</a>
						<div class="program" *ngIf="!program.broadcast">
							<div class="date">
								<p class="program-title mb-0">{{ program.program_date.day }}</p>
								<p class="program-subtitle mb-0 text-right">{{ program.program_date.time }}</p>
							</div>
							<div class="titles">
								<p class="program-title mb-0">{{ program.title }}</p>
								<p class="program-subtitle mb-0">{{ program.subtitle || '' }}</p>
							</div>
						</div>
					</ng-container>
				</div>
			</div>
			<div class="col-12">
				<div class="mt-3">
					<app-ad [page]="'home'" [location]="'homepage_top'"></app-ad>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-md-12">
				<a routerLink="/hirek/rallye">
					<h2 class="mt-5">Rallye Hírek <img src="/assets/img/black_arrow.svg" class="ml-4" alt="" /></h2>
				</a>
			</div>
		</div>
		<div class="row mb-4">
			<div class="col-md-3 col-6" *ngFor="let rallyePost of rallyePosts$ | async">
				<app-small-post-image [post]="rallyePost"></app-small-post-image>
			</div>
		</div>
	</div>
</section>
<section class="section-dark">
	<div class="container">
		<div class="row">
			<div class="col-md-12">
				<a routerLink="/adasok">
					<h2 class="mt-5">Adások <img src="/assets/img/right_arrow.svg" class="ml-4" alt="" /></h2>
				</a>
			</div>
		</div>
		<div class="row">
			<div class="col-md-12" *ngIf="featuredBroadcast$ | async">
				<ng-container *ngxLet="featuredBroadcast$ | async as featuredBroadcast">
					<app-selected-post-video [broadcast]="featuredBroadcast" *ngIf="featuredBroadcast"></app-selected-post-video>
				</ng-container>
			</div>
		</div>
		<div class="row">
			<div class="col-md-12"><h4 class="mt-5">VIDEÓK</h4></div>
		</div>
		<div class="row">
			<div class="col-6 col-md-3" *ngFor="let videoBroadcast of videoBroadcasts$ | async">
				<app-small-post-video [broadcast]="videoBroadcast"></app-small-post-video>
			</div>
		</div>
		<div class="row">
			<div class="col-md-12"><h4 class="mt-5">PODCASTEK</h4></div>
		</div>
		<div class="row">
			<div class="col-6 col-md-3" *ngFor="let podcastBroadcast of podcastBoadcasts$ | async">
				<app-small-post-video [broadcast]="podcastBroadcast"></app-small-post-video>
			</div>
		</div>
	</div>
</section>
<section class="mb-4">
	<div class="container">
		<div class="row">
			<div class="col-md-12">
				<a routerLink="/hirek/street">
					<h2 class="mt-5">Street Hírek<img src="/assets/img/black_arrow.svg" class="ml-4" alt="" /></h2>
				</a>
			</div>
		</div>
		<div class="row mt-3">
			<div class="col-md-3 col-6" *ngFor="let streetPost of streetPosts$ | async">
				<app-small-post-image [post]="streetPost"></app-small-post-image>
			</div>
		</div>
	</div>
</section>
<section class="bottom">
	<div class="container">
		<div class="row">
			<div class="col-12 col-md-6 mb-2 mb-md-0 fb-wall">
				<div class="fb-video">
					<iframe src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Frallyeradio%2F&tabs=timeline&small_header=false&width=500&height=500&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=1916404748485147" scrolling="no" frameborder="0" allowTransparency="true" allow="encrypted-media"></iframe>
				</div>
			</div>
			<div class="col-12 col-md-6">
				<div class="mb-2">
					<app-ad [page]="'home'" [location]="'homepage_bottom_1'"></app-ad>
				</div>
				<app-ad [page]="'home'" [location]="'homepage_bottom_2'"></app-ad>
			</div>
		</div>
	</div>
</section>
</ng-container>
