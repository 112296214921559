/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./loader.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./loader.component";
var styles_LoaderComponent = [i0.styles];
var RenderType_LoaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LoaderComponent, data: {} });
export { RenderType_LoaderComponent as RenderType_LoaderComponent };
export function View_LoaderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["class", "container loader-container"]], [[8, "hidden", 0]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t"])), (_l()(), i1.ɵeld(2, 0, null, null, 9, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t\t"])), (_l()(), i1.ɵeld(4, 0, null, null, 6, "div", [["class", "col-12 text-center"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i1.ɵeld(6, 0, null, null, 3, ":svg:svg", [["class", "loader mx-auto"], ["viewBox", "0 0 658 721.99"], ["xmlns", "http://www.w3.org/2000/svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 2, ":svg:g", [["data-name", "Layer 2"], ["id", "Layer_2"], ["style", "fill: #fff"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, ":svg:g", [["data-name", "Layer 1"], ["id", "Layer_1-2"], ["style", "fill: #fff"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 0, ":svg:path", [["class", "cls-1"], ["d", "M530,0H121L0,712H114L218,92H493c24,0,37,13,37,37s-13,37-37,37H254l-12,70c-18,116,21,166,120,166H465c24,0,38,13,38,37s-14,37-38,37H202l-12,70c-18,106,31,166,131,166H588V620H324c-42,0-63-28-55-74H505c75,0,118-43,118-118S580,310,505,310H377c-49,0-66-22-56-74H530c75,0,118-43,118-118S605,0,530,0Z"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t\t"])), (_l()(), i1.ɵted(-1, null, ["\n\t"])), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.isLoading; _ck(_v, 0, 0, currVal_0); }); }
export function View_LoaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-loader", [], null, null, null, View_LoaderComponent_0, RenderType_LoaderComponent)), i1.ɵdid(1, 114688, null, 0, i2.LoaderComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LoaderComponentNgFactory = i1.ɵccf("app-loader", i2.LoaderComponent, View_LoaderComponent_Host_0, { isLoading: "isLoading", message: "message" }, {}, []);
export { LoaderComponentNgFactory as LoaderComponentNgFactory };
