import { Component, OnInit } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { CMSService, Page, PostState } from '@app/cms';
import { Store } from '@ngrx/store';
import { ActivatedRoute, Router } from '@angular/router';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';
import { FacebookService, InitParams, UIParams, UIResponse } from 'ngx-facebook';
import { ClipboardService } from 'ngx-clipboard';
import { PostsAPIService } from '@app/cms/services/post.service';

@Component({
	selector: 'app-page',
	templateUrl: './page.component.html',
	styleUrls: ['./page.component.scss']
})
export class PageComponent implements OnInit {
	public tag: string;

	public page$: Observable<Page>;
	public isLoading$ = new BehaviorSubject(true);

	public url: string = '';

	public showCopiedMessage: boolean = false;

	constructor(
		private _route: ActivatedRoute,
		private _router: Router,
		private _titleService: Title,
		private fb: FacebookService,
		private _clipboardService: ClipboardService,
		private _postAPIService: PostsAPIService
	) {}

	ngOnInit() {
		const initParams: InitParams = {
			appId: '1916404748485147',
			xfbml: true,
			version: 'v3.2'
		};

		this.fb.init(initParams).then(value => console.log(value));

		this.page$ = this._route.paramMap.pipe(
			filter(params => params.has('slug')),
			map(params => params.get('slug')),
			switchMap(slug =>
				this._postAPIService.getPage(slug).pipe(
					tap(page => {
						this.isLoading$.next(false);
						if (page.title) this._titleService.setTitle(page.title + ' - Rallyeradio');
						if (page.slug) {
							this.url = 'https://rallyeradio.com/oldalak/' + page.slug;
						}
					}),
					filter(page => !!page)
				)
			)
		);
	}

	public shareFb() {
		const params: UIParams = {
			href: this.url,
			method: 'share'
		};

		this.fb
			.ui(params)
			.then((res: UIResponse) => console.log(res))
			.catch((e: any) => console.error(e));
	}

	public copy() {
		const copied = this._clipboardService.copyFromContent(this.url);
		if (copied) {
			this.showCopiedMessage = true;

			setTimeout(() => {
				this.showCopiedMessage = false;
			}, 5000);
		}
	}
}
