import { HttpClient } from '@angular/common/http';
import { of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
const ɵ0 = (c) => `/jokes/random?category=${c.category}`;
const routes = {
    quote: ɵ0
};
export class QuoteService {
    constructor(httpClient) {
        this.httpClient = httpClient;
    }
    getRandomQuote(context) {
        return this.httpClient
            .cache()
            .get(routes.quote(context))
            .pipe(map((body) => body.value), catchError(() => of('Error, could not load joke :-(')));
    }
}
export { ɵ0 };
