/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./small-post-image.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "./small-post-image.component";
var styles_SmallPostImageComponent = [i0.styles];
var RenderType_SmallPostImageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SmallPostImageComponent, data: {} });
export { RenderType_SmallPostImageComponent as RenderType_SmallPostImageComponent };
export function View_SmallPostImageComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 30, "div", [["class", "holder"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t"])), (_l()(), i1.ɵeld(2, 0, null, null, 7, "div", [["class", "aspect-ratio"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t\t"])), (_l()(), i1.ɵeld(4, 0, null, null, 4, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 5).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(5, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i1.ɵeld(7, 0, null, null, 0, "img", [["alt", ""], ["class", "img-fluid small-thumbnail"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t\t"])), (_l()(), i1.ɵted(-1, null, ["\n\t"])), (_l()(), i1.ɵted(-1, null, ["\n\t"])), (_l()(), i1.ɵeld(11, 0, null, null, 4, "div", [["class", "tag"]], null, null, null, null, null)), i1.ɵprd(512, null, i3.ɵNgClassImpl, i3.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(13, 278528, null, 0, i3.NgClass, [i3.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(14, { "bg-orange": 0, "bg-purple": 1 }), (_l()(), i1.ɵted(15, null, ["#", ""])), (_l()(), i1.ɵted(-1, null, ["\n\t"])), (_l()(), i1.ɵeld(17, 0, null, null, 8, "div", [["class", "title eq"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t\t"])), (_l()(), i1.ɵeld(19, 0, null, null, 5, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 20).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(20, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i1.ɵeld(22, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(23, null, ["", ""])), (_l()(), i1.ɵted(-1, null, ["\n\t\t"])), (_l()(), i1.ɵted(-1, null, ["\n\t"])), (_l()(), i1.ɵted(-1, null, ["\n\t"])), (_l()(), i1.ɵeld(27, 0, null, null, 2, "div", [["class", "date pb-2 mb-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(28, 0, null, null, 1, "small", [], null, null, null, null, null)), (_l()(), i1.ɵted(29, null, ["", ""])), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = i1.ɵinlineInterpolate(2, "/hirek/", _co.post.category.slug, "/", _co.post.slug, ""); _ck(_v, 5, 0, currVal_2); var currVal_4 = "tag"; var currVal_5 = _ck(_v, 14, 0, (_co.post.category.slug === "rallye"), (_co.post.category.slug === "street")); _ck(_v, 13, 0, currVal_4, currVal_5); var currVal_9 = i1.ɵinlineInterpolate(2, "/hirek/", _co.post.category.slug, "/", _co.post.slug, ""); _ck(_v, 20, 0, currVal_9); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 5).target; var currVal_1 = i1.ɵnov(_v, 5).href; _ck(_v, 4, 0, currVal_0, currVal_1); var currVal_3 = ((_co.post.featured_image_thumbnail || _co.post.featured_image) || "/assets/img/rallye-default.png"); _ck(_v, 7, 0, currVal_3); var currVal_6 = (_co.post.category.slug || "rallye"); _ck(_v, 15, 0, currVal_6); var currVal_7 = i1.ɵnov(_v, 20).target; var currVal_8 = i1.ɵnov(_v, 20).href; _ck(_v, 19, 0, currVal_7, currVal_8); var currVal_10 = (_co.post.title || ""); _ck(_v, 23, 0, currVal_10); var currVal_11 = (_co.post.published_ago || _co.post.publish_date); _ck(_v, 29, 0, currVal_11); }); }
export function View_SmallPostImageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-small-post-image", [], null, null, null, View_SmallPostImageComponent_0, RenderType_SmallPostImageComponent)), i1.ɵdid(1, 114688, null, 0, i4.SmallPostImageComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SmallPostImageComponentNgFactory = i1.ɵccf("app-small-post-image", i4.SmallPostImageComponent, View_SmallPostImageComponent_Host_0, { post: "post" }, {}, []);
export { SmallPostImageComponentNgFactory as SmallPostImageComponentNgFactory };
